export default {
  "public.targetGroup": "Targeting",
  "public.adCorrection": "Beweis",
  "public.adBudget": "Kauf",
  "public.sponsored": "Gesponsert",
  "public.swipe": "Swipe",
  "public.uniquePerImage": "Wenn einzigartig pro Bild gewählt, scrollen Sie nach unten, um den Text zu ändern",
  "public.gender&age": "Geschlecht & Alter",
  "public.emailExample": "example@email.com",
  "public.startDate": "Startdatum",
  "public.endDate": "Enddatum",
  "public.topMedia": "Top-Medien",
  "public.social": "Sozial",
  "public.display": "Anzeige",
  "public.display-dsp": "Anzeige - DSP",
  "public.webTv": "Web-TV",
  "public.video": "Video",
  "public.digitalScreen": "Digitaler Bildschirm (DOOH)",
  "public.devices": "Geräte",
  "public.media": "Medien",
  "public.url": "Url",
  "public.clicks": "Klicks",
  "public.createLink": "Link erstellen",
  "public.generateReport": "Bericht generieren",
  "public.showFullReport": "Vollständigen Bericht anzeigen",
  "public.impressions": "Eindrücke",
  "public.reach": "Reichweite",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR-Reichweite",
  "public.ctrImpressions": "CTR Impressionen",
  "public.channels": "Kanäle",
  "public.mobile": "Mobil",
  "public.desktop": "Desktop",
  "public.fbMobile": "Facebook mobil",
  "public.fbDesktop": "Facebook Desktop",
  "public.mediaBudget": "Medienbudget",
  "public.geoPlaces": "Geo-Targeting-Standorte",
  "public.reportRecipient": "Berichtsempfänger",
  "public.campaignManagers": "Kampagnen-Manager",
  "public.newAdImages": "Stellen Sie Bilder für Ihre Anzeige bereit",
  "public.currentAdImages": "Aktuelle Bilder für Ihre Anzeige",
  "public.adLandingPage": "Landing Page",
  "public.report": "Bericht",
  "public.orderProofStep": "Text auswählen",
  "public.orderTargetingStep": "Geo Targeting Standorte & Zielgruppe auswählen",
  "public.orderLocationStep": "Geo-Targeting-Standorte auswählen",
  "public.orderBudgetStep": "Budget, Zeitplan & Dashboard-Empfänger auswählen",
  "public.adTextAboveImage": "Anzeigenbeschreibung über dem Bild",
  "public.adTitleBelowImage": "Titel unter dem Bild",
  "public.descBelowImage": "Beschreibung unter dem Bild",
  "public.otherImages": "Andere verfügbare Bilder zur Verwendung in Ihrer Anzeige",
  "public.costCenter": "Kostenstelle",
  "public.chooseMediaBudget": "Medienbudget wählen",
  "public.addLinkedInJobPost": "Medienbudget für Stellenanzeige auf LinkedIn wählen",
  "public.linkedInAmount": "LinkedIn-Betrag",
  "public.noLinkedJobPost": "Keine LinkedIn-Stellenanzeige",
  "public.purchase": "Kauf",
  "public.purchaser": "Einkäufer",
  "public.purchased": "Gekauft",
  "public.perDayCost": "{value} SEK pro Tag",
  "public.sendNewLogin": "Neues Login senden",
  "public.extendAd": "Anzeige verlängern",
  "public.adTotal": "Gesamt:",
  "public.targetGroupControlledAds": " Gezielte Impressionen",
  "public.age": "Alter",
  "public.gender": "Geschlecht",
  "public.man": "Mann",
  "public.men": "Männer",
  "public.woman": "Frau",
  "public.women": "Frauen",
  "public.reference": "Referenz",
  "public.like": "Gefällt mir",
  "public.comment": "Kommentar",
  "public.share": "Teilen",
  "public.comments": "{value} Kommentare",
  "public.shares": "{value} shares",
  "public.minMaxAge": "Min {min}. Max {max}",
  "public.openNewWindow": "In neuem Fenster öffnen",
  "public.order": "Bestellung aufgeben",
  "public.next": "Nächster",
  "public.previous": "Vorherige",
  "public.adPeriod": "Zeitraum",
  "public.companyName": "Firmenname",
  "public.uploadImage": "Bild hochladen",
  "public.asap": "So bald wie möglich beginnen",
  "public.totalValue": "Total {value}",
  "public.lastShowDate": "Standardmäßig ermitteltes Enddatum",
  "public.createNewAdMultiEmail":
    "Bitte geben Sie alle zusätzlichen E-Mail-Adressen an, die das Campaign Dashboard erhalten sollen. Es können mehrere E-Mail-Adressen hinzugefügt werden.",
  "public.hintCampaignManagers":
    "Bitte geben Sie alle zusätzlichen E-Mail-Adressen an, die Sie für die Erweiterung der Kampagne autorisieren möchten. Es können mehrere E-Mail-Adressen hinzugefügt werden.",
  "public.addNewEmail": "E-Mail-Adresse hinzufügen, es können mehrere E-Mail-Adressen angegeben werden",
  "public.shortTextRecomended":
    "Halten Sie es kurz - wir empfehlen {recomendedLength} Zeichen oder weniger. Derzeit sind es {value} Zeichen.",
  "public.linkedInReporting": "Wir melden keine automatisch zielgerichteten Anzeigen von LinkedIn",
  "public.vatPrices": "Preise {vatType} {vatSuffix}",
  "public.vatPricesFull": "Preise {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Validierung von Geolocations",
  "public.tags": "Mehrere Tags hinzufügen, Text eingeben und Enter drücken",
  "public.tenantMediaLocale":
    "Fügen Sie ein Gebietsschema hinzu, indem Sie Text eingeben und die Eingabetaste drücken oder aus den Optionen auswählen.",
  "public.tenantMediaTags":
    "Fügen Sie mehrere Tags hinzu, geben Sie Text ein und drücken Sie die Eingabetaste oder wählen Sie aus den Optionen aus.",
  "public.tenantMediaCategories": "Fügen Sie Text hinzu und drücken Sie die Eingabetaste oder wählen Sie aus den Optionen aus.",
  "public.newAdConfirmation": "Sie erhalten eine Bestätigung Ihrer Bestellung an die angegebene E-Mail Adresse.",
  "public.adCreated": "Die Anzeige wurde erstellt und Sie können dieses Fenster nun schließen.",
  "public.minBudget": "Es müssen mindestens {value} Impressionen pro Tag sein, um den Auftrag zu erteilen.",
  "public.dragAndDropImages": `Bestimmen Sie die Reihenfolge und die Bilder, die per "Drag'n'Drop" angezeigt werden sollen`,
  "public.addTargetGroupLocation": "Fügen Sie Geolocation-Ziele hinzu, indem Sie nach Orten oder Adressen suchen.",
  "public.more": "... Mehr",
  "public.readMore": "...Mehr lesen",
  "public.fileDrop": "Klicken Sie, um Dateien auszuwählen oder ziehen Sie sie zum Hochladen hierher",
  "public.orders": "Bestellungen",
  "public.exclude": "Ex.",
  "public.include": "Ink.",
  "public.vatSuffix": "MwSt.",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Laden",
  "public.newLoginSent": "Neue Anmeldung gesendet",
  "public.errorLogin": "Fehler, bitte kontaktieren",
  "public.requestNewLogin": "Neue Anmeldung per E-Mail anfordern",
  "public.noImageSelected": "Kein Bild ausgewählt",
  "public.multiImageCategories": "Wählen Sie eine oder mehrere Bildkategorien",
  "public.infoSpecialAdCategory":
    "Demografische Einstellungen gesperrt, in Übereinstimmung mit den speziellen Anzeigenrichtlinien von Meta (Facebook & Instagram)",
  "public.edit": "Bearbeiten",
  "public.success": "Erfolg!",
  "public.orderPlaced": "Danke für Ihre Bestellung!",
  "public.hint.image": "Akzeptierte Dateitypen: JPG/PNG/BMP",
  "public.hint.font": "Akzeptierte Dateitypen: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Datum",
  "public.unauthorized": "Nicht autorisiert: Zugriff wird verweigert.",
  "public.permissiondenied": "Zugriff verweigert: Sie haben nicht die Erlaubnis, diesen Inhalt zu sehen",

  "imageTextOption.same": "Für alle gleich",
  "imageTextOption.unique": "Einzigartig pro Bild",

  "calltoaction.NO_BUTTON": "Keine Taste",
  "calltoaction.BUY_NOW": "Jetzt kaufen",
  "calltoaction.APPLY_NOW": "Jetzt bewerben",
  "calltoaction.BOOK_TRAVEL": "Reise buchen",
  "calltoaction.WATCH_MORE": "Mehr sehen",
  "calltoaction.CONTACT_US": "Kontaktieren Sie uns",
  "calltoaction.DOWNLOAD": "Herunterladen",
  "calltoaction.GET_OFFER": "Angebot erhalten",
  "calltoaction.GET_QUOTE": "Angebot abrufen",
  "calltoaction.SIGN_UP": "Anmelden",
  "calltoaction.SUBSCRIBE": "Abonnieren",
  "calltoaction.INTERESTED": "Interessiert",
  "calltoaction.GET_SHOWTIMES": "Vorstellungszeiten abrufen",
  "calltoaction.REQUEST_TIME": "Uhrzeit anfragen",
  "calltoaction.LEARN_MORE": "Mehr erfahren",
  "calltoaction.CALL_NOW": "Jetzt anrufen",

  "error.required": "Dieses Feld ist erforderlich",
  "error.cpm": "Cpm muss eine Zahl sein und im Bereich von 65 bis 900 liegen",
  "error.maxpm": "Cpm muss eine Zahl sein und im Bereich von 65 bis 250 liegen",
  "error.cpm.boundary.min": "Min muss kleiner als Max sein",
  "error.cpm.value": "Cpm muss eine Zahl sein und im Bereich von {min} bis {max} liegen",
  "error.cpm.boundaries.mixed": "Sowohl Min als auch Max müssen festgelegt sein",
  "error.number": "Dieses Feld muss eine Zahl sein",
  "error.chooseOneGender": "* Mindestens ein Geschlecht",
  "error.dailyBudgetLow":
    "Das Medienbudget ist zu niedrig, erhöhen Sie das Medienbudget oben oder verkürzen Sie den Kampagnenzeitplan.",
  "error.email": "Geben Sie eine gültige E-Mail an",
  "error.generic": "Etwas ist schief gelaufen, versuchen Sie es noch einmal",
  "error.imageUploadError": "Bilder konnten nicht gespeichert werden",
  "error.oneTargetGroupArea": "Mindestens ein Geostandort",
  "error.locationOverlap": "Geostandorte dürfen sich nicht überlappen",
  "error.noFacebookPage": "Geben Sie die Url Ihrer Facebook-Seite ein, um fortzufahren",
  "error.invalidFacebookPage": "Die Facebook-Seite ist nicht korrekt, überprüfen Sie Ihre Schreibweise",
  "error.badLink": "Die Landing Page ist nicht korrekt, überprüfen Sie Ihre Schreibweise",
  "error.noLandingPage": "Sie müssen Ihre Landing Page eingeben",
  "error.noGenderChoosen": "Kein Geschlecht ausgewählt",
  "error.noTargetGroupArea": "Mindestens ein Geostandort muss angegeben werden",
  "error.locationNoLocationOverlap": "Es muss mindestens ein geografischer Ort angegeben werden, der sich nicht überlappen darf",
  "error.closeEndDate":
    "Das Enddatum ist nur 48 Stunden vom Startdatum entfernt, das Enddatum muss 48 Stunden in der Zukunft liegen",
  "error.orderCouldNotComplete": "Die Bestellung konnte nicht verarbeitet werden",
  "error.fileTobig": "Die Datei ist zu groß, max 50mb, wählen Sie eine andere Datei",
  "error.notImageFile": "Die Datei muss ein Bild sein, wählen Sie eine andere Datei",
  "error.couldNotExtendProspect": "Die Anzeige konnte nicht erweitert werden, bitte versuchen Sie es später noch einmal.",
  "error.couldNotOrderAd": "Konnte Anzeige nicht bestellen",
  "error.channelDistribution": "Die Kanalverteilung muss insgesamt 100% betragen",
  "error.channelNumbers": "Es sollten mindestens 2 Kanäle konfiguriert sein",

  "public.error.proofRequired": "Mindestens ein Bild ist erforderlich",
  "public.error.proofDisplayRequired": "Mindestens {value} Bilder sind erforderlich",
  "public.error.hasEmptyPhoneNumbers": "Telefonnummer Das Feld darf nicht leer sein",
  "public.error.file-invalid-type": "Ungültiger Dateityp",
  "public.error.file-too-large": "Die Datei ist zu groß, max 50mb, wählen Sie eine andere Datei",
  "public.error.too-many-files": "Zu viele Dateien, wählen Sie eine",
  "public.error.pageNotFound": "Seite nicht gefunden!",
  "public.error.pageNotFoundDescription": "Bitte geben Sie eine gültige URL ein oder wenden Sie sich an den Support.",
  "public.error.pageNetworkError": "Interner Serverfehler",
  "public.error.pageNetworkErrorDescription": "Bitte versuchen Sie es später noch einmal, oder kontaktieren Sie den Support.",
  "public.error.landingPages": "Alle Landing Pages müssen eine gültige URL sein.",

  "common.save": "Speichern",
  "common.cancel": "Stornieren",
  "common.close": "Schließen",
  "common.choose": "Wählen",
  "common.pause": "Pause",
  "common.unpause": "Pause aufheben",
  "common.addValue": "{value} hinzufügen",
  "common.editValue": "{value} bearbeiten",
  "common.createValue": "{value} erstellen",
  "common.generateValue": "Erzeuge {value}",
  "common.copyValue": "Kopiere {value}",
  "common.changeValue": "Ändern {value}",
  "common.removeValue": "Entfernen {value}",
  "common.pauseValue": "Pause {value}",
  "common.unpauseValue": "Pause aufheben {value}",
  "common.deactivateValue": "Deaktivieren {value}",
  "common.selectValue": "Wähle {value}",
  "common.premadeValue": "Vorgefertigt {value}",
  "common.types": "Typen",
  "common.mailContact": "Kontakt {email}",
  "common.name": "Name",
  "common.category": "Kategorie",
  "common.adAccountStrategy": "Anzeigenkonto-Strategie",
  "common.adAccountId": "Anzeigenkonto-Id",
  "common.fallbackPageId": "Facebook Fallback Page Id",
  "common.fallbackLinkedInPageId":"LinkedIn Fallback Page Id",
  "common.dsaBeneficiary": "Begünstigter (DSA)",
  "common.dsaPayor": "Zahlungspflichtiger (DSA)",
  "common.cpm": "CPM",
  "common.targetCpm": "Target Cpm",
  "common.cpm.settings.min": "Min: ≥{value}",
  "common.cpm.settings.max": "Max: ≤{value}",
  "common.defaultMediaImages": "Standard-Medienbilder",
  "common.group": "Gruppe",
  "common.reseller": "Wiederverkäufer",
  "common.fbPage": "Facebook-Seite",
  "common.externalId": "Externe Id",
  "common.addMetadata": "Metadaten hinzufügen",
  "common.dataSource": "Datenquelle",
  "common.activeDataSource":"Aktive Datenquellen",
  "common.inactiveDataSource":"Inaktive Datenquellen",
  "common.template": "Vorlage",
  "common.deactivate": "Deaktivieren",
  "common.activate": "Aktivieren",
  "common.actions": "Aktionen",
  "common.totalAmount": "Gesamtbetrag",
  "common.conversionRate": "Umrechnungskurs",
  "common.thirtyTotalConversionRate": "30 Tage {thirty}% (Gesamt {total}%)",
  "common.cancelOrder": "Bestellung stornieren",
  "common.cancelCampaign": "Nein, abbrechen",
  "common.confirmRecreateCampaign": "Ja, neu erstellen",

  "common.created": "Erstellt",
  "common.prospect": "Interessent",
  "common.prospectSource": "Prospektquelle",
  "common.prospectSources": "Prospektquellen",
  "common.noEditValue": "{value} Nicht editierbar",
  "common.noRemoveValue": "{value} Nicht entfernbar",
  "common.rename": "Umbenennen",
  "common.prolong": "Kampagne verlängern",
  "common.isStartDate": "Budgetstartdatum hinzufügen",
  "common.configure": "Konfigurieren",
  "common.used": "Verwendet",
  "common.objectAmount": "Objektmenge",
  "common.modified": "Modifiziert",
  "common.failure": "Failure",
  "common.dataChange": "Datenänderung",
  "common.spidered": "Gecrawlt",
  "common.budget": "Budget",
  "common.setBudgetStartDate": "Startdatum festlegen",

  "common.remaining": "Verbleibend",
  "common.delete": "Löschen",
  "common.end": "Ende",
  "common.all": "Alle",
  "common.noCategory": "Keine Kategorie",
  "common.create": "Erstellen",
  "common.clearAll": "Alles löschen",
  "common.Price": "Preis",
  "common.email": "E-Mail",
  "common.returnToDashboard": "Zum Dashboard zurückkehren",
  "common.mobileNumber": "Handynummer",
  "common.send": "Senden",
  "common.title": "Titel",
  "common.password": "Passwort",
  "common.login": "Anmeldung",
  "common.logout": "Abmelden",
  "common.client": "Client",
  "common.active": "Aktiv",
  "common.opened": "Geöffnet",
  "common.completion": "Beendigung",
  "common.remove": "Entfernen",
  "common.error": "Fehler, IT kontaktieren",
  "common.filters": "Filter",
  "common.createdBy": "Ersteller",
  "common.field": "Feld",
  "common.type": "Typ",
  "common.patterns": "Patterns",
  "common.request": "Anfrage",
  "common.description": "Beschreibung",
  "common.themeColor": "Themenfarbe",
  "common.clientLogo": "Kundenlogo",
  "common.editGroup": "Gruppe bearbeiten",
  "common.yes": "Ja",
  "common.no": "Nein",

  "common.january": "Januar",
  "common.february": "Februar",
  "common.march": "März",
  "common.april": "April",
  "common.may": "Mai",
  "common.june": "Juni",
  "common.july": "Juli",
  "common.august": "August",
  "common.september": "September",
  "common.october": "Oktober",
  "common.november": "November",
  "common.december": "Dezember",

  "common.search": "Suche ...",
  "common.inactive": "Inaktiv",
  "common.showInactive": "Inaktiv anzeigen",
  "common.showSandboxed": "Sandboxe anzeigen",
  "common.copied": "Kopiert!",
  "common.copyCollectionValue": "Kopiere {length} von {collection} IDs",
  "common.campaign": "Kampagne",
  "common.tenant": "Partner",
  "common.targeting": "Targeting",
  "common.budgetPeriod": "Budgetperiode",
  "common.currencyCode": "Währungscode",
  "common.maxItemsInOrder": "Max Items In Order",
  "common.languageCode": "Sprachcode",
  "common.locale&currency": " Gebietsschema & Währung",

  "common.value": "Wert",
  "common.averageValue": "Durchschnittlicher {value}",

  "common.copy": "Kopie",
  "common.role": "Rolle",
  "common.status": "Status",
  "common.facebook": "Facebook",
  "common.bidTheatre": "Bidtheatre",
  "common.linkedIn": "LinkedIn",
  "common.clearChannel": "ClearChannel",
  "common.youTube": "YouTube (DV360)",
  "common.youTubeGoogleAds": "YouTube (GoogleAds)",

  "common.textFormat": "Textformat",
  "common.rootTextFormat": "Root Textformat",
  "common.itemTextFormat": "Element Texformat",
  "common.saveAndContinue": "Speichern und fortfahren",
  "common.exitWithoutSaving": "Beenden ohne Speichern",
  "common.resetPassword": "Passwort zurücksetzen",
  "common.id": "Id",
  "common.path": "Pfad",
  "common.format": "Format",
  "common.duration": "Dauer",
  "common.items": "Artikel",
  "common.groupings": "Gruppierungen",
  "common.previous": "Vorherige",
  "common.next": "Nächste",
  "common.page": "Seite",
  "common.pageSize": "Seitengröße",
  "common.crawlingSpeed": "Crawling-Geschwindigkeit",
  "common.cancelReason": "Abbruchgrund",
  "common.displayName": "Anzeigename",
  "common.tenantBranch": "Mieterfiliale",
  "common.groupName": "Gruppenname",
  "common.groupId": "Gruppen-ID",
  "common.facebookPageId": "Facebook-Seiten-ID",
  "common.billingType": "Abrechnungsart",
  "common.massSelection": "Massenauswahl",
  "common.massZipCode": "Postleitzahlen hinzufügen",
  "common.zipCode": "Postleitzahlen",
  "common.none": "Keine",

  "common.timeInterval": "Benutzerdefiniertes Intervall verwenden",
  "common.showDayAndTime": "Tag und Zeitintervall anzeigen",
  "common.customDateAndTime": "Datum und Uhrzeit",
  "common.crawlingSpindleSpeed": "Spindelgeschwindigkeit",
  "common.customDaySpeed": "Wochentage",
  "common.customHourSpeed": "Stunden",
  "common.customMinuteSpeed": "Minuten",
  "common.cronExpression": "Verwenden Sie den CRON-Ausdruck",
  "common.cronStatement": "ODER Sie können den CRON-Ausdruck unten verwenden",
  "title.selectedDay": "Ausgewählter Tag",

  "confirm.removeAutomations": "Sind Sie sicher, dass Sie diese Automationen dauerhaft löschen möchten?",
  "confirm.removeRecipientGroup": "Sind Sie sicher, dass Sie diese Empfängergruppe dauerhaft löschen wollen?",
  "confirm.removeValue": "Sind Sie sicher, dass Sie {value} dauerhaft löschen möchten?",
  "confirm.removeScheduledReport": "Sind Sie sicher, dass Sie diesen geplanten Bericht entfernen möchten?",
  "confirm.remove": "Sind Sie sicher, dass Sie ihn entfernen möchten?",
  "confirm.removeProvider": "Sind Sie sicher, dass Sie Provider {providerIndex} dauerhaft löschen wollen?",
  "confirm.openNewWindow": "Sind Sie sicher, dass Sie ein neues Fenster öffnen wollen?",
  "confirm.removeObjectName": "Sind Sie sicher, dass Sie diesen {value} dauerhaft löschen wollen?",
  "confirm.archive": "Sind Sie sicher, dass Sie archivieren möchten?",
  "confirm.deactivate": "Sind Sie sicher, dass Sie {value} deaktivieren möchten?",
  "confirm.activate": "Sind Sie sicher, dass Sie {value} aktivieren möchten?",
  "confirm.copyValue": "Sind Sie sicher, dass Sie {value} kopieren möchten?",
  "confirm.exitWithoutSaving": "Ihre Änderungen werden verloren gehen. Sind Sie sicher, dass Sie gehen wollen?",
  "confirm.resetPassword": "Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen möchten?",
  "confirm.cancel": "Sind Sie sicher, dass Sie die Bestellung {value} stornieren möchten?",
  "confirm.unpause": "Sind Sie sicher, dass Sie die Bestellung abbrechen möchten?",
  "confirm.generateKeys":
    "Wenn bereits ein Schlüssel generiert wurde und ein neuer Schlüssel generiert wird, verschwindet der alte und funktioniert nicht mehr. Sind Sie sicher, dass Sie einen neuen Schlüssel generieren möchten?",
  "confirm.insightSampleData": "Sind Sie sicher, dass Sie Einsichtsmusterdaten erstellen möchten?",
  "confirm.useInactiveDataSourceOnCampaign":
    "Sind Sie sicher, dass Sie die inaktive Datenquelle ({dataSource}) bei {campaign} einsetzen möchten?",

  "common.pinMeTo": "PinMeTo",
  "common.pinMeToAdAccountId": "PinMeTo Werbekonto Id",
  "common.pinMeToAppID": "PinMeTo App Id",
  "common.pinMeToAppSecret": "PinMeTo App Geheimnis",
  "common.enable": "Aktivierent",
  "common.disable": "Deaktiviert",
  "common.valid": "Gültig",
  "common.invalid": "Ungültig",

  "error.notValidInterest": "Kein gültiges Interesse",
  "error.noCampaignsFound": "Keine Kampagnen gefunden.",
  "error.lowValue": "Wert zu niedrig, mindestens {value}",
  "error.highValue": "Wert zu hoch, max {value}",
  "error.noFieldsFound": "Keine Felder gefunden",
  "error.pricePackage.small": "Dieses Feld muss kleiner als mittel sein",
  "error.pricePackage.medium": "Dieses Feld muss größer als klein und kleiner als groß sein",
  "error.pricePackage.large": "Dieses Feld muss größer als klein/mittel kleiner sein",
  "error.pricePackage.max": "Dieses Feld muss größer als groß sein",
  "error.automations": "Wenn die Automatisierung einen Empfänger enthält, muss dieser einen Namen und eine gültige E-Mail haben",
  "error.recipientGroups":
    "Empfängergruppen müssen einen Empfänger pro Empfängergruppe enthalten, der Empfängername darf nicht leer sein und muss eine gültige E-Mail haben",
  "error.dataSource": "Die Datenquelle kann nicht leer oder ungültiges JSON sein",
  "error.campaignSelectionNotSameGroupClient": "Alle ausgewählten Kampagnen haben nicht die gleiche Gruppe/Mandant",
  "error.couldNotCreateCollectionReport": "Konnte keinen Sammelbericht erstellen",
  "error.editLogo": `Konnte das Logo nicht aktualisieren, kontaktieren Sie den Techniker!`,
  "error.canNotChangeGroup": "Kann Gruppe nicht ändern",
  "error.budgetOrSubscription": "Kampagnen können nicht ohne Budget oder Abonnement erstellt werden",
  "error.languageCode": "Kein gültiger Sprachcode, ex en-au oder sv",
  "error.currencyCode": "Kein gültiger Währungscode, z.B. SEK oder USD",
  "error.noWhitespace": "Leerzeichen im Wert nicht erlaubt",
  "error.uppercase": "Wert muss in Großbuchstaben geschrieben werden",
  "error.lowercase": "Wert muss in Kleinbuchstaben sein",
  "error.clientNameUnique": "Kundenname muss eindeutig sein, existiert bereits in der Gruppe!",
  "error.groupNameUnique": "Gruppenname muss eindeutig sein, existiert bereits im Partner!",
  "error.partnerNameUnique": "Partnername muss eindeutig sein, existiert bereits!",
  "error.nameUnique": "Name muss eindeutig sein!",
  "error.minTwo": "Wert muss mindestens 2 Zeichen lang sein",
  "error.keyRequired": "Schlüssel ist erforderlich",
  "error.keyIsDuplicated": "Der Schlüssel ist nicht eindeutig",
  "error.login": "Ungültige Anmeldedaten",
  "error.emailUnique": "Benutzer mit dieser E-Mail existiert bereits",
  "error.tenantManager": "Kann keinen Tenant-Manager ohne Angabe des Tenants erstellen",
  "error.rolePassword": "Kann keinen Admin oder Tenant Manager ohne Passwort erstellen",
  "error.valueUndefined": "{value} undefined",
  "error.url": "Feld muss eine gültige URL sein",
  "error.rootLink": "Wurzel erfordert ein Link-Feld",
  "error.idsUnique": "Ids müssen eindeutig sein",
  "error.oneItem": "Ein Element ist erforderlich",
  "error.deliveryGuarantee": "Die Liefergarantie muss eine Zahl sein und im Bereich von 1 bis 100 liegen",
  "error.idsMaxItems": "Zu viele Ids, max 30",

  "error.errorMessageMoreMinute": "Minuten müssen größer als 0 sein",
  "error.errorMessageLessMinute": "Die Minuten dürfen höchstens 59 betragen",
  "error.errorMessageMoreHours": "Stunden müssen größer als 0 sein",
  "error.errorMessageLessHours": "Die Stundenzahl darf höchstens 23 betragen",

  "success.collectionReport": "Sammelbericht erstellt und an tech@spiderads.io gesendet",
  "success.createDataSource": `Datenquelle erstellt!`,
  "success.editDataSource": `Datenquelle bearbeitet!`,
  "success.editThemeColor": `Themecolor Edited!`,
  "success.editLogo": `Logo aktualisiert!`,
  "success.userCreated": `Benutzer erstellt!`,
  "success.editRole": `Rolle aktualisiert!`,
  "success.editMobileNumber": `Handynummer aktualisiert!`,

  "hint.usedBy": "Verwendet von {campaigns}",
  "hint.notInUse": "Nicht in Gebrauch",
  "hint.channelProgress": `Gesamt: {impressions} Gebucht: {bookedImpressions} Ausgeliefert: {deliveredImpressions} Fertigstellung: {completion}%`,
  "hint.addRecipient": "Empfänger hinzufügen durch Eingabe einer gültigen E-Mail",
  "hint.addCategory": "Fügen Sie eine Kategorie hinzu, oder lassen Sie das Feld leer, wenn Sie keine Kategorie angeben",
  "hint.colorPicker": "Wählen Sie eine Farbe aus der Palette oder fügen Sie einen Hex-Farbcode ein",
  "hint.warning.display": "Stellen Sie sicher, dass die gewählte Vorlage für bidTheatre konfiguriert ist",
  "hint.massSelection": "Fügen Sie {value} kommagetrennt oder mit Zeilenumbruch ein",
  "hint.allCampaignsSceduledReport": "Leer lassen für alle Kampagnen auf dem Client",

  "tooltip.campaignSelection": "Kampagnen, die Sie in der Liste ausgewählt haben",
  "tooltip.pickTemplateMassUpdate": "Wählen Sie die Vorlage, auf die die Kampagnenauswahl aktualisiert werden soll",
  "tooltip.overrideLabel":
    "Wenn Sie die Standardbeschriftung außer Kraft setzen möchten: Referenz (Referenznummer, Kostenstelle oder Person)",
  "tooltip.overridePlaceholder":
    "Wenn Sie den Standardplatzhalter außer Kraft setzen möchten: Referenz (Referenznummer, Kostenstelle oder Person)",
  "tooltip.prefilledField":
    "Wenn Sie das standardmäßig vorab ausgefüllte Feld überschreiben möchten: Referenz (Referenznummer, Kostenstelle oder Person)",
  "tooltip.dataSourceName": "Der Name, den Sie für die Datenquelle verwenden möchten",
  "tooltip.inheritDataSource": "Die Datenquelle, von der Sie die Einstellungen erben möchten",
  "tooltip.inheritFilters": "Wenn Sie Filter aus der geerbten Datenquelle kombinieren oder zurücksetzen möchten",
  "tooltip.variables": "Variablen, die Sie verwenden möchten, z.B.: url",
  "tooltip.url": "Fügen Sie den Url-Pfad zu der Webseite ein, von der Sie die Metadaten abrufen möchten",
  "tooltip.headers": "Wenn Sie Anfrage-Header hinzufügen möchten, z.B.: ':scheme: https'",
  "tooltip.userAgent":
    "Wenn die Webseite keine unbekannten Benutzeragenten zulässt, z.B.: Mozilla/5.0 (kompatibel; Googlebot/2.1; +http://www.google.com/bot.html)",
  "tooltip.formData": "Wenn Sie der Anfrage Formulardaten hinzufügen möchten",
  "tooltip.numberOfResults": "Wenn Sie die Anzahl der Ergebnisse begrenzen möchten",
  "tooltip.rootData": "Bei gemeinsam genutzten Anzeigen sollten Sie Titel und Beschreibung in die Wurzel eingeben",
  "tooltip.itemsData": "Für individuelle Anzeigen, fügen Sie Titel und Beschreibung in die Artikel ein",
  "tooltip.container": "Welches HTML-Tag oder welcher Json-Schlüssel die Datenquelle abrufen soll, z.B.: $.items[*]",
  "tooltip.automationCreated":
    "Automatisierungen automatisieren nur Interessenten, die nach der Erstellung der Automatisierung gefunden wurden, bestehende Interessenten sind davon nicht betroffen.",
  "tooltip.defaultBudgetAmount":
    "Fallback Budgethöhe bei automatischen Bestellungen, kann in der Datenquelle überschrieben werden",
  "tooltip.defaultDuration": "Fallback-Dauer (Tage) bei automatischen Bestellungen, kann in der Datenquelle überschrieben werden",
  "tooltip.defaultGeo": "Fallback Geo bei automatischen Bestellungen, kann in der Datenquelle überschrieben werden",
  "tooltip.defaultRadius": "Rückfallradius bei automatischen Bestellungen, Standard ist 3km",
  "tooltip.sorting": "Wenn Sie möchten, dass der Spider die Ergebnisse in aufsteigender oder absteigender Reihenfolge sortiert.",
  "tooltip.totalClicksValue": "Gesamtklicks: {value}",
  "tooltip.allowedFontFamily": "Nur ttf/woff-Schriften erlaubt",
  "tooltip.image": "Wählen Sie ein Bild als Wert",

  "title.automaticReport": "Automatischer Leistungsbericht per E-Mail",

  "title.newAdImges": "Bilder für die Anzeige (Bildanzeige, wenn nur ein Bild, sonst eine Karussellanzeige)",
  "title.companyFacebookPage": "Facebook-Seite",

  "title.orderRecived": "Bestellung ist eingegangen",
  "title.resultReport": "Leistungsbericht",
  "title.numAdShow": "Wie oft die Anzeige gezeigt wurde",
  "title.numAdClickSee": "Wie viele Personen, die die Anzeige gesehen haben, auf sie geklickt haben",
  "title.numAdClick": "Wie viele Klicks auf die Anzeige",
  "title.fb&bt": "Facebook {fb}% - Bidtheatre {bt}%",
  "title.channelShare": "Kanalanteil",
  "title.useCustomStaticCreatives": "Verwenden Sie benutzerdefinierte statische Motive",
  "title.customStaticCreatives": "Sie benutzerdefinierte statische Motive",
  "title.createCampaign": "Kampagne erstellen",
  "title.cpmValue": "Cpm {value}",
  "title.campaignCategory": "Kampagnen-Kategorie",
  "title.campaignDefaults": "Kampagnen-Standardwerte",
  "title.clients": "Kunden",
  "title.latestProspect": "Letzter Interessent",
  "title.latestOrder": "Letzter Auftrag",
  "title.numberOfProspect": "Anzahl der Prospects",
  "title.configStaticData": "Statische Datenquelle konfigurieren",
  "title.createAdvancedDataSource": "Erweiterte Datenquelle erstellen",
  "title.createStaticDataSource": "Statische Datenquelle erstellen",
  "title.dataSources": "Datenquellen",
  "title.notes": "Notizen",
  "title.templates": "Vorlagen",
  "title.archive": "Archiv",
  "title.viewInBaka": "In Baka ansehen",
  "title.campaigns": "Kampagnen",
  "title.settings": "Einstellungen",
  "title.videoTemplates": "Video-Vorlagen",
  "title.videoPlacements": "Videoplatzierungen",
  "title.groups": "Gruppen",
  "title.orders": "Bestellungen",
  "title.createNewCampaign": "Neue Kampagne erstellen",
  "title.createBudgetPeriod": "Budgetperiode erstellen",
  "title.budgetPeriods": "Budget-Perioden",
  "title.clientDashboardCampaigns": "Clientdashboard/Kampagnen",
  "title.value": "Wert",
  "title.clientDashboard": "Kunden-Dashboard",
  "title.images": "Bilder",
  "title.uploadImages": "Bilder hochladen",
  "title.schedule": "Zeitplan",
  "title.recipients": "Empfänger",
  "title.specifiedEmail": "Angegebene E-Mail-Adresse",
  "title.recipient": "Empfänger",
  "title.scheduledReport": "Geplanter Bericht",
  "title.scheduledReports": "Geplante Berichte",
  "title.specifiedEmailadress": "Email eingeben",
  "title.sendOrderEmail": "Bestell-E-Mail an bestimmte E-Mail-Adresse senden",
  "title.allCampaigns": "Alle Kampagnen",
  "title.previewProspect": "Vorschau-Prospekt",
  "title.canAddLinkedInBudget": "Kann LinkedIn Budget hinzufügen",
  "title.canAddCampaignManagers": "Kann Kampagnenmanager hinzufügen",
  "title.hasMassSelection": "Hat Massenauswahl Bilder",
  "title.updateforallclients": "Alle Kunden einschließen",
  "title.hasMassZipCode": "Hat Massen-Postleitzahl",
  "title.applyMarginSetting": "MargingWRTSplash anwenden",
  "title.mediaLibararyCategory": "Kategorie für Mediathek",
  "title.prefix": "Präfix für Kampagnennamen",
  "title.suffix": "Suffix des Kampagnennamens",
  "public.costCenterreferencefield": "Kostenstelle/Referenzfeld",
  "title.customfieldType": "Benutzerdefinierter Feldtyp",
  "title.filtering": "Filterung",
  "title.customField": "Benutzerdefiniertes Feld",
  "title.sorting": "Sortierung",
  "title.prospectType": "Art des Prospects",
  "title.staticDataSource": "Statische Datenquelle",
  "title.dynamicDataSource": "Dynamische Datenquelle",
  "title.canUpload": "Bild-Upload-Einstellungen",
  "title.endDateSetting": "Einstellung des Enddatums",
  "title.preview": "Vorschau",
  "title.recipientFilter": "Empfänger-Filter",
  "title.addAutomation": "Automatisierung hinzufügen",
  "title.trigger": "Auslöser",
  "title.addTrigger": "Auslöser hinzufügen",
  "title.small": "Klein",
  "title.medium": "Mittel",
  "title.large": "Groß",
  "title.maxAmountLarge": "Maximaler Betrag für groß",
  "title.automationsSettings": "Automationseinstellungen",
  "title.defaultBudgetAmount": "Fallback Budgethöhe",
  "title.defaultDuration": "Fallback-Dauer (Tage)",
  "title.defaultGeo": "Fallback Geo",
  "title.defaultRadius": "Fallback Radius",
  "title.addFilter": "Filter hinzufügen",
  "title.recipientGroups": "Empfängergruppen",
  "title.fallbackRecipient": "Als Ersatzempfänger verwenden, nur wenn kein Empfänger beim Interessenten gefunden wird.",
  "title.addRecipient": "Empfänger hinzufügen",
  "title.addRecipientGroup": "Empfängergruppe hinzufügen",
  "title.removeRecipientGroup": "Empfängergruppe entfernen",
  "title.removeAutomation": "Automatisierung entfernen",
  "title.pricePackageCurrencyCode": "Preispaket in {value}",
  "title.dataSourceName": "Name der Datenquelle",
  "title.providers": "Anbieter",
  "title.evaluated": "Ausgewertet",
  "title.evaluate": "Ausgewertet",
  "title.result": "Ergebnis",
  "title.output": "Ausgabe",
  "title.managers": "Manager",
  "title.manager": "Manager",
  "title.orderNumber": "Bestellnummer",
  "title.prospect": "Interessent",
  "title.amount": "Betrag",
  "title.allowCallAds": "Call Ads zulassen",
  "title.massUpdateCampaign": "Vorlage für ausgewählte Kampagnen bearbeiten",
  "title.addNote": "Notiz hinzufügen",
  "title.campaignSelection": "Ausgewählte Kampagnen",
  "title.SelectedValue": "Ausgewählter {value}",
  "title.campaignSelectionValue": "{value} Ausgewählte Kampagnen",
  "title.dataSourceSelectionValue": "{value} Ausgewählte Datenquellen",
  "title.exportToExcel": "Nach Excel exportieren",
  "title.createCollectionReport": "Kollektionsbericht erstellen",
  "title.overrideLabel": "Überschreibendes Label",
  "title.prefilledField": "Vorab ausgefüllte Kostenstelle",
  "title.overridePlaceholder": "Überschreibungsplatzhalter",
  "title.exclusions": "Ausschlüsse",
  "title.addExclusions": "Ausschlüsse hinzufügen",
  "title.spiderTemplate": "Vorlage",
  "title.inheritSettings": "Einstellungen vererben",
  "title.variables": "Variablen",
  "title.addVariable": "Variable hinzufügen",
  "title.container": "Container",
  "title.userAgent": "Benutzeragent",
  "title.restrictNumberOfResults": "Anzahl der Ergebnisse einschränken",
  "title.sortResult": "Ergebnis sortieren",
  "title.numberOfResults": "Anzahl der Ergebnisse",
  "title.headers": "Kopfzeilen",
  "title.formData": "Formulardaten",
  "title.addHeader": "Kopfzeile hinzufügen",
  "title.addFormData": "Formulardaten hinzufügen",
  "title.key": "Schlüssel",
  "title.rootData": "Root-Daten",
  "title.addRootData": "Wurzeldatenfeld hinzufügen",
  "title.replacements": "Ersetzungen",
  "title.addReplacement": "Ersetzung hinzufügen",
  "title.removeRootData": "Root-Datenfeld entfernen",
  "title.selector": "Auswähler",
  "title.transformScript": "Transformationsskript",
  "title.pattern": "Muster",
  "title.replacement": "Ersetzung",
  "title.recursive": "Rekursiv",
  "title.itemsData": "Artikeldaten",
  "title.addItemData": "Artikeldatenfeld hinzufügen",
  "title.removeItemData": "Artikeldatenfeld entfernen",
  "title.addProvider": "Anbieter hinzufügen",
  "title.typeOfFilter": "Typ des Filters",
  "title.requestVerificationToken": "Verifizierungs-Token anfordern",
  "title.requestVerificationUrl": "Anfrage-Überprüfungs-Url",
  "title.requestCredentialsToken": "Request Credentials Token",
  "title.requestCredentialsToken.url": "Authentifizierungs-Endpunkt",
  "title.requestCredentialsToken.tokenSelector": "Token-Selektor",
  "title.resolveCanonical": "Kanonische Urls auflösen",
  "title.subscription": "Abonnement",
  "title.monthlyBudget": "Monatliches Budget",
  "title.sameForAllMonths": "Gleiches Budget für alle Monate",
  "title.users": "Benutzer",
  "title.user": "Benutzer",
  "title.manuallyEnded": "Manuell Beendet",
  "title.updateCampaigns": "Kampagnen aktualisieren",
  "title.updateProspectSources": "Prospektquellen aktualisieren",
  "title.tags": "Tags",
  "title.locale": "Gebietsschema",
  "title.mediaCategories": "Medienkategorie",
  "title.brokerageFee": "Brokerage Fee",
  "title.metadata": "Metadaten",
  "title.sandbox": "Sandkasten",
  "title.templateName": "Geben Sie Ihrer Vorlage einen Namen",
  "title.billingType": "Abrechnungsart",
  "title.deliveryGuarantee": "Liefergarantie",
  "title.background": "Hintergrund",
  "title.color": "Farbe",
  "title.image": "Bild",
  "title.size": "Größe",
  "title.landscapeSize": "Querformat",
  "title.portraitSize": "Hochformat",
  "title.positionY": "Position Y",
  "title.dimmerColor": "Dimmer-Farbe",
  "title.dimmerOpacity": "Dimmer Deckkraft",
  "title.defaults": "Voreinstellungen",
  "title.sampleEmail": "Beispiel-E-Mail",
  "title.editClientEmailStopSettings": "Bearbeiten Sie die E-Mail-Einstellungen des Kunden",
  "title.editCampaignEmailStopSettings": "Bearbeiten Sie die E-Mail-Einstellungen der Kampagne",
  "title.emailType": "Art der E-Mail",
  "title.emailTemplateSettings": "Einstellungen der E-Mail-Vorlage",
  "title.headerFont": "Kopfzeilen-Schriftart",
  "title.contentFont": "Inhaltsschrift",
  "title.supportBox": "Unterstützungsbox",
  "title.footer": "Fußzeile",
  "title.emailTemplateInfo": "E-Mail-Vorlage Info",
  "title.subject": "Betreff",
  "title.markdownContent": "Markdown-Inhalt",
  "title.emailTemplateType": "E-Mail-Vorlagentyp",
  "title.metaMedia": "Meta Media (Facebook & Instagram)",
  "title.website": "Website",
  "title.appDomain": "App-Domäne",
  "title.cdnDomain": "CDN-Domäne",
  "title.emailDomain": "E-Mail-Domäne",
  "title.fromEmail": "Von E-Mail",
  "title.productTitle": "Produkttitel",
  "title.productLink": "Produkt link",
  "title.smsSender": "SMS-Absender",
  "title.orderRecipient": "Bestellungsempfänger",
  "title.apiChannels": "Zulässige API-Kanäle",
  "title.state": "Zustand",
  "title.reason": "Grund",
  "title.dataSourceFailures": "Ausfälle der Datenquelle",
  "title.insightsKey": "Insights-Schlüssel",
  "title.apiKey": "Api-Schlüssel",
  "title.saveKey":
    "Speichern Sie den Zugangsschlüssel, es ist nicht möglich, den Schlüssel erneut zu erhalten, ohne einen neuen Schlüssel zu erstellen.",
  "title.documentation": "Dokumentation: {value}",
  "title.magicTokenUsages": "Magic Token Verwendungen",
  "title.mediaLibrarySettings": "Medialibrary-Einstellungen",
  "title.allowPublicClientMetadata": "Öffentliche Client-Metadaten zulassen",
  "title.insightsSampleData": "Einsichtsmusterdaten",
  "title.prospecting": "Prospecting",
  "title.integrationModule": "Integrationsmodul",
  "title.partnerApi": "Partner-API",
  "title.emailSelection": "E-Mail-Auswahl",
  "title.stopEmailSelection": "Wählen Sie unten, welche automatische E-Mail Sie stoppen möchten",
  "title.creationSource": "Erstellt von",
  "title.emails": "Emails",
  "title.opened": "Geöffnet",
  "title.language": "Sprache",
  "title.enablePinMeTo": "Aktivieren Sie PinMeTo",
  "title.pinMeToCampaign": "Als PinMeTo-Kampagne festlegen",
  "title.integratePinMeToCampaign":
    "Kampagne {integratedCampaign} ist integriert. Bestätigen Sie die Überschreibung mit der Kampagne {campaignName}",
  "title.linkedInPageId": "LinkedIn-Referenz-ID",
  "title.linkedInAdAccountId": "LinkedIn-Anzeigenkonto-ID",

  "placeholder.noGroup": "Keine Gruppe",
  "placeholder.noReseller": "Kein Wiederverkäufer",
  "placeholder.noDataSourceChoosen": "Keine Datenquelle ausgewählt",
  "placeholder.noTemplateChoosen": "Keine Vorlagenauswahl",
  "placeholder.mobileExample": "+46723395200",
  "placeholder.chooseColor": "Farbe wählen",
  "placeholder.chooseCategory": "Kategorie wählen",
  "placeholder.noTenant": "Kein Partner",
  "placeholder.chooseLanguage": "Sprache wählen",

  "sliderLabel.fb&bt0": "Facebook {value}%",
  "sliderLabel.fb&bt1": "Bidtheatre {value}%",
  "sliderLabel.facebook": "Facebook {value}%",
  "sliderLabel.bidTheatre": "Bidtheatre {value}%",
  "sliderLabel.youTube": "YouTube {value}%",
  "sliderLabel.youTubeGoogleAds": "YouTube {value}%",

  "documentTitle.resultReport": "Leistungsbericht für {campaignName}",

  "filterType.equals": "Gleich",
  "filterType.contains": "Enthält",
  "filterType.notContains": "Enthält nicht",
  "filterType.lessThan": "Kleiner als",
  "filterType.greaterThan": "Größer als",
  "filterType.hasValue": "Hat Wert",
  "filterType.noValue": "Hat keinen Wert",
  "filterType.regex": "Regex-Übereinstimmung",
  "filterType.regexInverted": "Negative Regex",
  "filterType.in": "In",
  "filterType.notIn": "Nicht in",

  "filterLogicType.or": "Oder",
  "filterLogicType.and": "Und",
  "filterLogicType.or_not": "Oder nicht",
  "filterLogicType.and_not": "Und nicht",

  "scheduledReportType.weekly": "Wöchentlich",
  "scheduledReportType.monthly": "Monatlich",
  "scheduledReportType.quaterly": "Vierteljährlich",
  "scheduledReportType.yearly": "Jährlich",

  "referenceType.none": "Nicht anzeigen",
  "referenceType.optional": "Optional",
  "referenceType.required": "Erforderlich",
  "referenceType.locked": "Gesperrt",

  "prospectType.object": "Objekt",
  "prospectType.job": "Job",
  "prospectType.offer": "Angebot",
  "prospectType.realEstate": "Immobilien",

  "landingPageSetting.uneditable": "Unbearbeitbar",
  "landingPageSetting.same": "Für alle gleich",
  "landingPageSetting.unique": "Einzigartig",

  "canUploadSetting.no": "Kein Upload",
  "canUploadSetting.toProspect": "Kann zu Prospect hochladen",
  "canUploadSetting.toClient": "Kann in die Mediathek des Kunden hochladen",
  "canUploadSetting.toHQ": "Kann in die Mediathek im HQ hochladen",

  "channelCampaignState.active": "Aktiv",
  "channelCampaignState.ended": "Beendet",
  "channelCampaignState.scheduled": "Geplant",
  "channelCampaignState.warning": "Warnung",
  "channelCampaignState.broken": "Gebrochen",

  "enum.campaignStateType.archived": "Archiviert",
  "enum.campaignStateType.paused": "Pausiert",
  "enum.campaignStateType.ended": "Beendet",
  "enum.campaignStateType.scheduled": "Geplant",
  "enum.campaignStateType.live": "Live",

  "enum.role.admin": "Admin",
  "enum.role.user": "Benutzer",
  "enum.role.prospectUser": "ProspectUser",
  "enum.role.tenantManager": "TenantManager",
  "enum.role.apiKeyUser": "ApiKeyUser",

  "enum.userStatus.active": "Aktiv",
  "enum.userStatus.inactive": "Inaktiv",

  "enum.endDateSetting.none": "Keine",
  "enum.endDateSetting.monday": "Montag",
  "enum.endDateSetting.tuesday": "Dienstag",
  "enum.endDateSetting.wednesday": "Mittwoch",
  "enum.endDateSetting.thursday": "Donnerstag",
  "enum.endDateSetting.friday": "Freitag",
  "enum.endDateSetting.saturday": "Samstag",
  "enum.endDateSetting.sunday": "Sonntag",

  "enum.adAccountStrategy.group": "Gruppe",
  "enum.adAccountStrategy.client": "Kunde",
  "enum.adAccountStrategy.tenant": "Mieter",

  "enum.groupMediaLibrarySettings.everythingAvailable": "Alles verfügbar",
  "enum.groupMediaLibrarySettings.onlyClientOrHqWhenAvailable": "Nur Client oder Hq, wenn verfügbar",

  "enum.emailType.orderCampaignCreate": "Bestellungskampagne erstellen",
  "enum.emailType.orderCampaignExtend": "Bestellungskampagne verlängern",
  "enum.emailType.campaignLive": "Kampagne Live",
  "enum.emailType.campaignEnd": "Kampagne Beendet",
  "enum.creationSourceType.manual": "Manuell",
  "enum.creationSourceType.prospecting": "Anwerbung",
  "enum.creationSourceType.prospectingAutomation": "Automatisiertes Prospecting",
  "enum.creationSourceType.integration": "Integration",
  "enum.creationSourceType.api": "Partner-API",
  "enum.creationSourceType.draftTemplate": "Entwurfsvorlage",
  "enum.creationSourceType.batch": "Batch",

  "adsLastUpdated.all": "Alle",
  "adsLastUpdated.oneDay": "> 1 Tag",
  "adsLastUpdated.twoDays": "> 2 Tage",
  "adsLastUpdated.threeDays": "> 3 Tage",
  "adsLastUpdated.fourDays": "> 4 Tage",
  "adsLastUpdated.fiveDays": "> 5 Tage",
  "adsLastUpdated.sixDays": "> 6 Tage",
  "adsLastUpdated.oneWeek": "> 1 Woche",
  "adsLastUpdated.twoWeeks": "> 2 Wochen",
  "adsLastUpdated.threeWeeks": "> 3 Wochen",

  "crawlSpeed.00:15:00": "15 Minuten",
  "crawlSpeed.00:30:00": "30 Minuten",
  "crawlSpeed.00:45:00": "45 Minuten",
  "crawlSpeed.01:00:00": "1 Stunde",
  "crawlSpeed.03:00:00": "3 Stunden",
  "crawlSpeed.06:00:00": "6 Stunden",
  "crawlSpeed.12:00:00": "12 Stunden",
  "crawlSpeed.1.00:00:00": "1 Tag",
  "crawlSpeed.3.00:00:00": "3 Tage",
  "crawlSpeed.7.00:00:00": "7 Tage",

  "time.seconds": "Sekunden",
  "time.minutes": " Minuten",
  "time.hours": " Stunden",
  "time.days": " Tage",

  "dataSourceProviderTemplateFilter.combine": "kombinieren",
  "dataSourceProviderTemplateFilter.reset": "zurücksetzen",

  "recipientMap.title": "Zuordnungen für Recipients",
  "recipientMap.addRecipientMap": "Zuordnung hinzufügen",
  "recipientMap.override": "Außerkraftsetzen",
  "recipientMap.reference.error": "Referenz kann nicht leer sein.",
  "recipientMap.override.error":
    "Override darf nicht leer sein und muss gültig sein, wenn es sich um eine E-Mail-Adresse handelt.",

  "enum.ascending": "Aufsteigend",
  "enum.descending": "Absteigend",

  "billingType.unspecified": "Nicht spezifiziert",
  "billingType.monthly": "Monatlich im Voraus",
  "billingType.monthlyArrears": "Monatlich im Rückstand",
  "billingType.quarterly": "Vierteljährlich im Voraus",
  "billingType.semiYearly": "Halbjährlich im Voraus",
  "billingType.yearly": "Jährlich im Voraus",
  "billingType.onDemand": "Bei Bestellung im Webshop",
  "billingType.external": "Extern",
  "cancelReason.duplicate": "Duplikat",
  "cancelReason.regretted": "Bedauert",
  "cancelReason.incorrect": "Falsch",

  "videoFormats.square": "Quadratisch",
  "videoFormats.portrait9x16": "Hochformat9x16",
  "videoFormats.landscape16x9": "Querformat16x9",
  "videoFormats.vertical": "Vertikal",
  "videoFormats.all": "Alle",
  "videoFormats.none": "Keine",

  "sampleEmailType.prospectFound": "Interessent gefunden",
  "sampleEmailType.prospectOrdered": "Interessent Bestellt",
  "sampleEmailType.prospectExtended": "Erweiterter Interessent",
  "sampleEmailType.prospectCampaignLive": "Interessent Kampagne Live",
  "sampleEmailType.prospectCampaignEnded": "Interessentenkampagne beendet",
  "sampleEmailType.scheduledReport": "GeplanterBericht",

  "adCategory.IAB1": "Arts/Ent",
  "adCategory.IAB10": "Zuhause",
  "adCategory.IAB10-1": "Haushaltsgeräte",
  "adCategory.IAB10-2": "Unterhalten",
  "adCategory.IAB10-3": "Umwelt",
  "adCategory.IAB10-4": "Gartenarbeit",
  "adCategory.IAB10-5": "Zuhause",
  "adCategory.IAB10-6": "Zuhause",
  "adCategory.IAB10-7": "Interieur",
  "adCategory.IAB10-8": "Landschaftsbau",
  "adCategory.IAB10-9": "Umgestaltung",
  "adCategory.IAB11": "Recht",
  "adCategory.IAB1-1": "Bücher",
  "adCategory.IAB11-2": "Recht",
  "adCategory.IAB11-4": "Politik (S)",
  "adCategory.IAB12": "Nachrichten",
  "adCategory.IAB1-2": "Berühmtheit",
  "adCategory.IAB12-1": "International",
  "adCategory.IAB12-2": "National",
  "adCategory.IAB12-3": "Lokal",
  "adCategory.IAB1-3": "Fein",
  "adCategory.IAB13": "Persönlich (S)",
  "adCategory.IAB13-1": "Beginnend (S)",
  "adCategory.IAB13-10": "Ruhestand (S)",
  "adCategory.IAB13-11": "Aktien (S)",
  "adCategory.IAB13-12": "Steuern (S)",
  "adCategory.IAB13-2": "Kredit/Schulden (S)",
  "adCategory.IAB13-3": "Finanzen (S)",
  "adCategory.IAB13-4": "Finanziell (S)",
  "adCategory.IAB13-5": "Absicherung (S)",
  "adCategory.IAB13-6": "Versicherung (S)",
  "adCategory.IAB13-7": "Investieren (S)",
  "adCategory.IAB13-8": "Gegenseitigkeit (S)",
  "adCategory.IAB13-9": "Optionen (S)",
  "adCategory.IAB14": "Gesellschaft",
  "adCategory.IAB1-4": "Humor",
  "adCategory.IAB14-1": "Partnersuche",
  "adCategory.IAB14-2": "Scheidung",
  "adCategory.IAB14-4": "Heirat",
  "adCategory.IAB14-5": "Senior",
  "adCategory.IAB14-6": "Teenager",
  "adCategory.IAB14-7": "Hochzeiten",
  "adCategory.IAB15": "Wissenschaft",
  "adCategory.IAB1-5": "Filme",
  "adCategory.IAB15-10": "Wetter",
  "adCategory.IAB15-2": "Biologie",
  "adCategory.IAB15-3": "Chemie",
  "adCategory.IAB15-4": "Geologie",
  "adCategory.IAB15-6": "Physik",
  "adCategory.IAB15-7": "Weltraum/Astronomie",
  "adCategory.IAB15-8": "Geographie",
  "adCategory.IAB15-9": "Botanik",
  "adCategory.IAB16": "Haustiere",
  "adCategory.IAB1-6": "Musik",
  "adCategory.IAB16-1": "Aquarien",
  "adCategory.IAB16-2": "Vögel",
  "adCategory.IAB16-3": "Katzen",
  "adCategory.IAB16-4": "Hunde",
  "adCategory.IAB16-5": "Groß",
  "adCategory.IAB16-6": "Reptilien",
  "adCategory.IAB16-7": "Tierärztlich",
  "adCategory.IAB17": "Sport",
  "adCategory.IAB1-7": "Fernsehen",
  "adCategory.IAB17-1": "Auto",
  "adCategory.IAB17-10": "Figur",
  "adCategory.IAB17-11": "Fliegen",
  "adCategory.IAB17-12": "Fußball",
  "adCategory.IAB17-13": "Süßwasser",
  "adCategory.IAB17-14": "Spiel",
  "adCategory.IAB17-15": "Golf",
  "adCategory.IAB17-16": "Pferd",
  "adCategory.IAB17-17": "Pferde",
  "adCategory.IAB17-18": "Jagen/Schießen",
  "adCategory.IAB17-19": "Inline",
  "adCategory.IAB17-2": "Baseball",
  "adCategory.IAB17-20": "Kampfsport",
  "adCategory.IAB17-21": "Berg",
  "adCategory.IAB17-23": "Olympische Spiele",
  "adCategory.IAB17-24": "Paintball",
  "adCategory.IAB17-25": "Strom",
  "adCategory.IAB17-26": "Profi",
  "adCategory.IAB17-27": "Pro",
  "adCategory.IAB17-28": "Rodeo",
  "adCategory.IAB17-29": "Rugby",
  "adCategory.IAB17-3": "Radsport",
  "adCategory.IAB17-30": "Laufen/Joggen",
  "adCategory.IAB17-31": "Segeln",
  "adCategory.IAB17-32": "Salzwasser",
  "adCategory.IAB17-33": "Tauchen",
  "adCategory.IAB17-34": "Skateboarding",
  "adCategory.IAB17-35": "Skifahren",
  "adCategory.IAB17-36": "Snowboarden",
  "adCategory.IAB17-37": "Surfen/Bodyboarding",
  "adCategory.IAB17-38": "Schwimmen",
  "adCategory.IAB17-39": "Tisch",
  "adCategory.IAB17-4": "Bodybuilding",
  "adCategory.IAB17-40": "Tennis",
  "adCategory.IAB17-41": "Volleyball",
  "adCategory.IAB17-42": "Wandern",
  "adCategory.IAB17-43": "Wasserski/Wakeboard",
  "adCategory.IAB17-44": "Welt",
  "adCategory.IAB17-5": "Boxen",
  "adCategory.IAB17-6": "Kanu/Kajak",
  "adCategory.IAB17-7": "Cheerleading",
  "adCategory.IAB17-8": "Klettern",
  "adCategory.IAB17-9": "Kricket",
  "adCategory.IAB18": "Stil",
  "adCategory.IAB18-1": "Schönheit",
  "adCategory.IAB18-2": "Körper",
  "adCategory.IAB18-3": "Mode",
  "adCategory.IAB18-4": "Schmuck",
  "adCategory.IAB18-5": "Kleidung",
  "adCategory.IAB18-6": "Accessoires",
  "adCategory.IAB19": "Technik",
  "adCategory.IAB19-5": "Kameras",
  "adCategory.IAB19-6": "Handy",
  "adCategory.IAB2": "Automobil (S)",
  "adCategory.IAB20": "Reisen",
  "adCategory.IAB20-1": "Abenteuer",
  "adCategory.IAB20-10": "Kanada",
  "adCategory.IAB20-11": "Karibik",
  "adCategory.IAB20-12": "Kreuzfahrten",
  "adCategory.IAB20-13": "Ostern",
  "adCategory.IAB20-14": "Europa",
  "adCategory.IAB20-15": "Frankreich",
  "adCategory.IAB20-16": "Griechenland",
  "adCategory.IAB20-17": "Flitterwochen/Urlaub",
  "adCategory.IAB20-18": "Hotels",
  "adCategory.IAB20-19": "Italien",
  "adCategory.IAB20-2": "Afrika",
  "adCategory.IAB20-20": "Japan",
  "adCategory.IAB20-21": "Mexiko",
  "adCategory.IAB20-22": "National",
  "adCategory.IAB20-23": "Süden",
  "adCategory.IAB20-24": "Heilbäder",
  "adCategory.IAB20-25": "Thema",
  "adCategory.IAB20-26": "Reisen",
  "adCategory.IAB20-27": "Vereint",
  "adCategory.IAB20-3": "Luft",
  "adCategory.IAB20-4": "Australien",
  "adCategory.IAB20-5": "Bett",
  "adCategory.IAB20-6": "Budget",
  "adCategory.IAB20-7": "Business",
  "adCategory.IAB20-8": "Nach",
  "adCategory.IAB20-9": "Camping",
  "adCategory.IAB21": "Immobilien (S)",
  "adCategory.IAB21-1": "Wohnungen (S)",
  "adCategory.IAB21-2": "Architekten (S)",
  "adCategory.IAB21-3": "Kaufen/Verkaufen (S)",
  "adCategory.IAB2-1": "Auto",
  "adCategory.IAB2-10": "Elektrisch",
  "adCategory.IAB2-11": "Fließheck",
  "adCategory.IAB2-12": "Hybrid",
  "adCategory.IAB2-13": "Luxus",
  "adCategory.IAB2-14": "MiniVan",
  "adCategory.IAB2-15": "Motorräder",
  "adCategory.IAB2-16": "Off-Road",
  "adCategory.IAB2-17": "Leistung",
  "adCategory.IAB2-18": "Pickup",
  "adCategory.IAB2-19": "Straßenseite",
  "adCategory.IAB22": "Einkaufen",
  "adCategory.IAB2-2": "Auto",
  "adCategory.IAB2-20": "Limousine",
  "adCategory.IAB2-21": "Lastwagen",
  "adCategory.IAB22-1": "Wettbewerbe",
  "adCategory.IAB2-22": "Weinlese",
  "adCategory.IAB22-2": "Couponing",
  "adCategory.IAB2-23": "Kombi",
  "adCategory.IAB22-4": "Motoren",
  "adCategory.IAB23": "Religion",
  "adCategory.IAB2-3": "Kaufen/Verkaufen (S)",
  "adCategory.IAB23-1": "Alternativ",
  "adCategory.IAB23-10": "Heidnisch/Wicca",
  "adCategory.IAB23-2": "Atheismus/Agnostizismus",
  "adCategory.IAB23-3": "Buddhismus",
  "adCategory.IAB23-4": "Katholizismus",
  "adCategory.IAB23-5": "Christentum",
  "adCategory.IAB23-6": "Hinduismus",
  "adCategory.IAB23-7": "Islam",
  "adCategory.IAB23-8": "Judentum",
  "adCategory.IAB2-4": "Auto",
  "adCategory.IAB2-5": "Zertifiziert",
  "adCategory.IAB2-6": "Cabrio",
  "adCategory.IAB2-7": "Coupé",
  "adCategory.IAB2-8": "Crossover",
  "adCategory.IAB2-9": "Diesel",
  "adCategory.IAB3": "Business",
  "adCategory.IAB3-1": "Werbung",
  "adCategory.IAB3-10": "Logistik",
  "adCategory.IAB3-11": "Marketing",
  "adCategory.IAB3-12": "Metalle",
  "adCategory.IAB3-2": "Landwirtschaft",
  "adCategory.IAB3-3": "Biotechnologie/Biomedizin",
  "adCategory.IAB3-4": "Wirtschaft",
  "adCategory.IAB3-5": "Bauwesen",
  "adCategory.IAB3-6": "Forstwirtschaft",
  "adCategory.IAB3-7": "Regierung",
  "adCategory.IAB3-8": "Grün",
  "adCategory.IAB3-9": "Menschlich",
  "adCategory.IAB4": "Karriere (S)",
  "adCategory.IAB4-1": "Karriere (S)",
  "adCategory.IAB4-10": "U.S. (S)",
  "adCategory.IAB4-11": "Karriere (S)",
  "adCategory.IAB4-2": "Hochschule (S)",
  "adCategory.IAB4-3": "Finanzen (S)",
  "adCategory.IAB4-4": "Job (S)",
  "adCategory.IAB4-5": "Job (S)",
  "adCategory.IAB4-6": "Lebenslauf (S)",
  "adCategory.IAB4-7": "Krankenpflege (S)",
  "adCategory.IAB4-8": "Stipendien (S)",
  "adCategory.IAB5": "Bildung",
  "adCategory.IAB5-1": "7-12",
  "adCategory.IAB5-10": "Homeschooling",
  "adCategory.IAB5-11": "Hausaufgaben/Studium",
  "adCategory.IAB5-12": "K-6",
  "adCategory.IAB5-13": "Privat",
  "adCategory.IAB5-14": "Spezial",
  "adCategory.IAB5-15": "Studieren",
  "adCategory.IAB5-2": "Erwachsen",
  "adCategory.IAB5-3": "Kunst",
  "adCategory.IAB5-4": "Hochschule",
  "adCategory.IAB5-5": "Hochschule",
  "adCategory.IAB5-6": "Entfernung",
  "adCategory.IAB5-7": "ESL",
  "adCategory.IAB5-8": "Sprache",
  "adCategory.IAB5-9": "Hochschulabsolvent",
  "adCategory.IAB6": "Familie",
  "adCategory.IAB6-1": "Adoption",
  "adCategory.IAB6-2": "Babies",
  "adCategory.IAB6-3": "Kindertagesstätte/Vorschule",
  "adCategory.IAB6-4": "Familie",
  "adCategory.IAB6-5": "Kindererziehung",
  "adCategory.IAB6-6": "Kindererziehung",
  "adCategory.IAB6-7": "Schwangerschaft",
  "adCategory.IAB6-8": "Spezial",
  "adCategory.IAB6-9": "Altenpflege",
  "adCategory.IAB7": "Gesundheit",
  "adCategory.IAB7-1": "Bewegung",
  "adCategory.IAB7-10": "Gehirn",
  "adCategory.IAB7-11": "Krebs",
  "adCategory.IAB7-12": "Cholesterin",
  "adCategory.IAB7-13": "Chronisch",
  "adCategory.IAB7-14": "Chronisch",
  "adCategory.IAB7-15": "Erkältung/Grippe",
  "adCategory.IAB7-16": "Taubheit",
  "adCategory.IAB7-17": "Zahnarzt",
  "adCategory.IAB7-18": "Depression",
  "adCategory.IAB7-19": "Dermatologie",
  "adCategory.IAB7-2": "GESCHLECHTSKRANKHEITEN",
  "adCategory.IAB7-20": "Diabetes",
  "adCategory.IAB7-21": "Epilepsie",
  "adCategory.IAB7-22": "GERD/Acid",
  "adCategory.IAB7-23": "Kopfschmerzen/Migräne",
  "adCategory.IAB7-24": "Herz",
  "adCategory.IAB7-27": "Reizdarmsyndrom/Krohns",
  "adCategory.IAB7-29": "Inkontinenz",
  "adCategory.IAB7-3": "AIDS/HIV",
  "adCategory.IAB7-30": "Unfruchtbarkeit",
  "adCategory.IAB7-31": "Männer",
  "adCategory.IAB7-32": "Ernährung",
  "adCategory.IAB7-33": "Orthopädie",
  "adCategory.IAB7-34": "Panik/Angst",
  "adCategory.IAB7-35": "Pädiatrie",
  "adCategory.IAB7-36": "Körperlich",
  "adCategory.IAB7-37": "Psychologie/Psychiatrie",
  "adCategory.IAB7-38": "Senor",
  "adCategory.IAB7-4": "Allergien",
  "adCategory.IAB7-40": "Schlaf",
  "adCategory.IAB7-41": "Rauchen",
  "adCategory.IAB7-42": "Substanz",
  "adCategory.IAB7-43": "Schilddrüse",
  "adCategory.IAB7-44": "Gewicht",
  "adCategory.IAB7-45": "Frauen",
  "adCategory.IAB7-6": "Arthritis",
  "adCategory.IAB7-7": "Asthma",
  "adCategory.IAB7-8": "Autismus/PDD",
  "adCategory.IAB7-9": "Bipolar",
  "adCategory.IAB8": "Ernährung",
  "adCategory.IAB8-1": "Amerikanisch",
  "adCategory.IAB8-10": "Lebensmittel",
  "adCategory.IAB8-11": "Französisch",
  "adCategory.IAB8-12": "Gesundheit/Fettarm",
  "adCategory.IAB8-13": "Italienisch",
  "adCategory.IAB8-14": "Japanisch",
  "adCategory.IAB8-15": "Mexikanisch",
  "adCategory.IAB8-16": "Vegan",
  "adCategory.IAB8-17": "Vegetarisch",
  "adCategory.IAB8-18": "Wein",
  "adCategory.IAB8-2": "Barbecues",
  "adCategory.IAB8-3": "Cajun/Kreolisch",
  "adCategory.IAB8-4": "Chinesisch",
  "adCategory.IAB8-5": "Cocktails/Bier",
  "adCategory.IAB8-6": "Kaffee/Tee",
  "adCategory.IAB8-7": "Kulinarische Spezialitäten",
  "adCategory.IAB8-8": "Desserts/Backen",
  "adCategory.IAB8-9": "Essen",
  "adCategory.IAB9": "Hobbys",
  "adCategory.IAB9-1": "Kunst/Technik",
  "adCategory.IAB9-10": "Sammeln",
  "adCategory.IAB9-11": "Comic",
  "adCategory.IAB9-12": "Zeichnen/Skizzieren",
  "adCategory.IAB9-13": "Freiberuflich",
  "adCategory.IAB9-14": "Genealogie",
  "adCategory.IAB9-15": "Bekommen",
  "adCategory.IAB9-16": "Gitarre",
  "adCategory.IAB9-17": "Zuhause",
  "adCategory.IAB9-18": "Investoren",
  "adCategory.IAB9-19": "Schmuck",
  "adCategory.IAB9-2": "Kunst",
  "adCategory.IAB9-20": "Magie",
  "adCategory.IAB9-21": "Handarbeit",
  "adCategory.IAB9-22": "Malerei",
  "adCategory.IAB9-23": "Fotografie",
  "adCategory.IAB9-24": "Radio",
  "adCategory.IAB9-25": "Rollenspiele",
  "adCategory.IAB9-26": "Sci-Fi/Fantasy",
  "adCategory.IAB9-27": "Scrapbooking",
  "adCategory.IAB9-28": "Drehbuchschreiben",
  "adCategory.IAB9-29": "Briefmarken",
  "adCategory.IAB9-3": "Perlenarbeiten",
  "adCategory.IAB9-30": "Video",
  "adCategory.IAB9-31": "Holzbearbeitung",
  "adCategory.IAB9-4": "Vogelbeobachtung",
  "adCategory.IAB9-5": "Tafel",
  "adCategory.IAB9-6": "Kerze",
  "adCategory.IAB9-7": "Karte",
  "adCategory.IAB9-8": "Schach",
  "adCategory.IAB9-9": "Zigarren",
}
